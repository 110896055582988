import { graphql, Script, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import CustomAccordion from "../components/common/accordian"
import Buttons from "../components/common/button"
import Frame from "../components/common/frame"
import PopUpForm from "../components/common/PopUpForm"
import Navigation from "../components/navigation"
import Slide from "../components/utility/slide_content"
import FeaturedTopics from "../components/webinar/featured-topics"
import "../styles/component/faq.scss"
import "../styles/component/pricing-faq.scss"
import "../styles/pages/affiliate.scss"

const Affiliate = () => {
  const [popupVisibility, setPopupVisibility] = useState(false)
  const togglePopup = () => {
    setPopupVisibility(!popupVisibility)
  }
  const formdata = [
    {
      name: "firstname",
      type: "text",
      label: "Name",
      errorMessage: "Please enter a valid name",
    },
    {
      name: "email",
      type: "email",
      label: "Email address",
      errorMessage: "Please enter a valid email",
    },
    {
      name: "company",
      type: "text",
      label: "Company name",
      errorMessage: "Please enter company",
    },
    {
      name: "phone",
      type: "phone",
      label: "Mobile number",
      errorMessage: "Please enter a mobile number",
    },
    {
      name: "affiliate_type",
      type: "singleSelect",
      label: "What describes you the best?",
      value: null,
      choices: [
            { label: "MSP", value: "MSP" },
            { label: "Influencer", value: "Influencer" },
            { label: "Agencies serving MSP", value: "Agencies serving MSP" },
            { label: "Vendors or VARs", value: "Vendors or VARs" },
            { label: "Others", value: "Others" }
          ],
      errorMessage: "Please select a type",
    },
    {
      name: "interested_in_superops_community",
      type: "radio",
      title: "Would you be willing to give some of your time to the SuperOps Community?",
      choices: ["Yes", "No"],
      data: ["Yes", "No"],
      required: false,
    },
  ]

  const ValueBox = props => {
    return (
      <div className={`Value-box ${props.className}`}>
        <div className={`value ${props.valueClassName}`}>
          <p className="symbol">{props.symbol}</p>
          <p className="number">{props.value}</p>
        </div>
        <div className="content">{props.content}</div>
      </div>
    )
  }

  const DescriptionBox = props => {
    return props.data.linkName.map((el, idx) => {
      return (
        <Col lg={6}>
          <div className="Description-box">
            <div className="heading">
              <img src={props.data.icon[idx].url} alt="" />
              <p className="fw-bold">{el}</p>
            </div>

            <div>
              <p className="description">{props.data.authorName[idx]}</p>
            </div>
          </div>
        </Col>
      )
    })
  }

  const responsive = {
    desktopLarge: {
      breakpoint: { max: 3000, min: 1401 },
      items: 4,
    },

    desktopMedium: {
      breakpoint: { max: 1400, min: 1200 },
      items: 3.4,
    },
    desktopSmall: {
      breakpoint: { max: 1199, min: 992 },
      items: 3.3,
    },

    tablet: {
      breakpoint: { max: 992, min: 800 },
      items: 3,
    },

    mobile1: {
      breakpoint: { max: 800, min: 730 },
      items: 2.5,
    },
    mobile2: {
      breakpoint: { max: 730, min: 600 },
      items: 2.1,
    },
    mobile3: {
      breakpoint: { max: 600, min: 580 },
      items: 1.9,
    },
    mobile4: {
      breakpoint: { max: 580, min: 539 },
      items: 1.7,
    },
    mobilel: {
      breakpoint: { max: 539, min: 500 },
      items: 1.6,
    },
    mobileLarge: {
      breakpoint: { max: 500, min: 441 },
      items: 1.24,
      partialVisibilityGutter: 24,
    },
    mobile: {
      breakpoint: { max: 440, min: 421 },
      items: 1.18,
      partialVisibilityGutter: 24,
    },
    mobileSmall: {
      breakpoint: { max: 420, min: 0 },
      items: 1,
      // partialVisibilityGutter: 24,
    },
  }

  return (
    <>
      <StaticQuery
        query={graphql`
          query AffiliatePage {
            SuperOps {
              pages(where: { title: "Affiliate program" }) {
                title
                content {
                  html
                }
                subtitle
                pageBlock {
                  id
                  content {
                    html
                    text
                  }
                }
                pageImages {
                  url
                }
                links {
                  slug
                  linkName
                  icon {
                    url
                  }
                  authorName
                }
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
              }
              faqs(where: { page: "AFFILIATE" }) {
                category
                question
                answerrt {
                  html
                }
              }
            }
          }
        `}
        render={data => (
          <div className="affiliate-page">
            {data.SuperOps.pages.map(page => {
              const {
                title,
                pageBlock,
                content,
                subtitle,
                navigationBlock,
                seo,
                links,
                pageImages,
              } = page
              return (
                <>
                  <Script
                    src="https://app.revenuehero.io/scheduler.min.js"
                    onLoad={() => {
                      window.addEventListener("message", e => {
                        if (e.data.type === "rhFormSubmit") {
                          const hero = new window.RevenueHero({ routerId: "1732" })
                          hero
                            .submit(e.data.formData)
                            .then(data => {
                              hero.dialog.setEmbedTarget("#SuperOpsaffiliateform")
                              hero.dialog.open(data)
                            })
                            .catch(error => console.error(error))
                        }
                      })
                    }}
                  />
                  <Frame
                    title={seo.title}
                    description={seo.description}
                    keywords={seo.keywords}
                    ogTitle={seo.title}
                    ogDescription={seo.description}
                    ogImage={seo.image}
                    ogType="website"
                  >
                    <header>
                      <Navigation
                        links={navigationBlock}
                        page="Home"
                        color={"#FFF1FC"}
                      />
                    </header>

                    <div>
                    <PopUpForm
                        visibility={popupVisibility}
                        togglePopup={val => {
                          setPopupVisibility(val)
                        }}
                        formData={formdata}
                        endpoint={process.env.HUBSPOT_AFFILIATE_ENDPOINT}
                        url={process.env.HUBSPOT_AFFILIATE_URL}
                        formName="Affiliate Signup- Superops"
                        formTitle="We'd love to have you on board. Enter your details, and we'll
                        get in touch with you."
                        // path="affiliate"
                        embedTarget="SuperOpsaffiliateform"
                        hideImage
                      />
                    </div>

                    <main>
                      <section className="Hero">
                        <Container>
                          <center>
                            <Slide delay="200">
                              <div className="p14 ">
                                <p className="active">{title.toLowerCase()}</p>
                              </div>
                            </Slide>

                            <Slide delay="200">
                              <div className="heading">
                                {parse(content.html)}
                              </div>
                            </Slide>
                            <Slide delay="200">
                              <div className="subtitle p18">
                                <p>{subtitle}</p>
                              </div>
                            </Slide>

                            <Slide delay="200">
                              <div className="reward">
                                <ValueBox
                                  symbol={"$"}
                                  value={parse(pageBlock[0].content.html)}
                                  content={parse(pageBlock[1].content.html)}
                                />
                                <div className="p24">
                                  {parse(pageBlock[2].content.html)}
                                </div>
                                <ValueBox
                                  symbol={"%"}
                                  valueClassName="flex-row-reverse"
                                  value={parse(pageBlock[3].content.html)}
                                  content={parse(pageBlock[4].content.html)}
                                />
                              </div>
                            </Slide>

                            <Slide delay="200">
                              <div className="p18 p mb-4 text-grey">
                                <p>{parse(pageBlock[5].content.html)}</p>
                              </div>
                            </Slide>

                            <div>
                              <Slide delay="200">
                                <Buttons
                                  theme="primary"
                                  arrow
                                  text={parse(pageBlock[6].content.text)}
                                  onClick={() => togglePopup()}
                                />
                              </Slide>
                            </div>
                          </center>
                        </Container>
                      </section>

                      <section className="Top-section">
                        <Container>
                          <div className="content position-relative">
                            <Slide delay="200">
                              <h2 className="title">
                                {parse(pageBlock[7].content.text)}
                              </h2>
                            </Slide>

                            <Slide delay="200">
                              <div className="features-description">
                                <Row>
                                  <Col lg={8} className="mx-auto">
                                    <Row>
                                      <DescriptionBox data={links[0]} />
                                    </Row>
                                  </Col>
                                  <div className="p14 p text-grey mx-auto text-center">
                                    <p>{parse(pageBlock[8].content.html)}</p>
                                  </div>
                                </Row>
                              </div>
                            </Slide>

                            <Slide delay="200">
                              <img
                                src={pageImages[0].url}
                                alt=""
                                className="d-dsk illustration-left"
                              />
                            </Slide>
                            <Slide delay="200">
                              <img
                                src={pageImages[1].url}
                                alt=""
                                className="d-dsk illustration-right"
                              />
                            </Slide>
                          </div>
                        </Container>
                      </section>

                      <section className="Carousel-section">
                        <Container>
                          <Slide delay="200">
                            <h2 className="title">
                              {parse(pageBlock[9].content.text)}
                            </h2>
                          </Slide>

                          <div className="Carousel-cards">
                              <FeaturedTopics
                                FeaturedTopics={links[1].linkName}
                                FeaturedTopicsBg={links[1].slug}
                                status
                                statusTextRight="featured"
                                responsive={responsive}
                              />

                            <Slide delay="400">
                              <div className="text-center">
                                <Buttons
                                  theme="primary"
                                  arrow
                                  text={parse(pageBlock[6].content.text)}
                                  onClick={() => togglePopup()}
                                />
                              </div>
                            </Slide>
                          </div>
                        </Container>
                      </section>

                      <section className="faq affiliate-page">
                        <Container>
                          <Slide delay="200">
                            <h2 className="title">
                              {parse(pageBlock[10].content.text)}
                            </h2>
                          </Slide>

                          <Slide delay="200">
                            <div className="p18 p text-grey text-center mt-4">
                              <p>{parse(pageBlock[11].content.text)}</p>
                            </div>
                          </Slide>

                          <center>
                            <Slide delay="200">
                              <div className="outer">
                                <CustomAccordion items={data.SuperOps.faqs} />
                              </div>
                            </Slide>
                          </center>
                        </Container>
                      </section>
                    </main>
                  </Frame>
                </>
              )
            })}
          </div>
        )}
      />
    </>
  )
}

export default Affiliate
